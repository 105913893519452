import React from 'react'

export default function About() {
  return (
    <div>
  
<section className="filmoja-about-page-area section_70">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="about-slider owl-carousel owl-loaded owl-drag">
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(-1705px, 0px, 0px)",
                transition: "all 2s ease 0s",
                width: 5115
              }}
            >
              <div
                className="owl-item cloned"
                style={{ width: "832.5px", marginRight: 20 }}
              >
                <div className="single-about-slider">
                  <img src="./1.jpg" alt="sldier" />
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: "832.5px", marginRight: 20 }}
              >
                <div className="single-about-slider">
                  <img src="./2.jpg" alt="sldier" />
                </div>
              </div>
              <div
                className="owl-item active"
                style={{ width: "832.5px", marginRight: 20 }}
              >
                <div className="single-about-slider">
                  <img src="./1.jpg" alt="sldier" />
                </div>
              </div>
              <div
                className="owl-item"
                style={{ width: "832.5px", marginRight: 20 }}
              >
                <div className="single-about-slider">
                  <img src="./1.jpeg" alt="sldier" />
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: "832.5px", marginRight: 20 }}
              >
                <div className="single-about-slider">
                  <img src="./2.jpg" alt="sldier" />
                </div>
              </div>
              <div
                className="owl-item cloned"
                style={{ width: "832.5px", marginRight: 20 }}
              >
                <div className="single-about-slider">
                  <img src="./2.jpg" alt="sldier" />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-nav">
            <button type="button" role="presentation" className="owl-prev">
              <i className="fa fa-long-arrow-left" />
            </button>
            <button type="button" role="presentation" className="owl-next">
              <i className="fa fa-long-arrow-right" />
            </button>
          </div>
          <div className="owl-dots disabled" />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="about-page-text">
          <h3>about our journey</h3>
          <p>
          Founded in July 2015, Pushkar Rangmanch is a manifestation of like-minded individuals coming together to either reconnect with or explore their interest in theatre. The foundation of Pushkar Rangmanch was laid when a popular group, The Dot Movement, gathered people who believed in creating an environment where individuals could motivate each other to pursue their passions and interests while living their regular lives.

Pushkar Rangmanch serves as a platform for all those who have always wanted to act or be involved in theatre, whether it's in aspects like direction, set designing, lights, sounds, or backstage activities.

The name "Pushkar Rangmanch" pays homage to our beloved friend, Pushkar Kulkarni, who left us last year, leaving behind the responsibility of carrying forward his love for theatre. Our aim is to make theatre accessible to all those who, at least once in their lives, thought of connecting with it. Pushkar, an excellent storyteller naturally drawn to acting, was a warm, friendly, extremely helpful person who always radiated enthusiasm for life.
          </p>
          <p>
          Our group meets exclusively on weekends, embarking on a collective journey of learning and exploring theatre and acting. We firmly believe that there is no perfect age to start. If you are interested, don't hesitate to join us. We proudly declare ourselves a non-judgmental platform, embracing the idea that if acting brings you happiness, you should pursue it without reservation. This excitement, in turn, contributes to personal improvement and growth.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
