import { Routes, Route } from "react-router-dom"
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Container/Home';
import About from './Container/About';
import Contact from './Container/Contact';

function App() {
  return (
    <div className="App">
     <>
     <Header/>
     <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="about" element={ <About/> } />
        <Route path="contact" element={ <Contact/> } />
      </Routes>
     <Footer/>
     </>
    </div>
  );
}

export default App;
