import React from 'react';

export default function Footer() {
    const footerStyle = {
        backgroundColor: '#f8f9fa',
        padding: '20px 0',
        textAlign: 'center',
    };

    const contactInfoStyle = {
        margin: 0,
    };

    return (
        <footer style={footerStyle}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <a href="#">
                        <img src="./logo.jpeg" alt="footer logo" style={{ height: '8rem', margin: '0 0 10px 0' }} />
                    </a>
                    <p style={contactInfoStyle}>Vidyanchal School, Pune</p>
                    <p style={contactInfoStyle}>Maharashtra, India</p>
                    <p style={contactInfoStyle}>Mail Us: <a href="mailto:pushkarrangmanch@gmail.com">pushkarrangmanch@gmail.com</a></p>
                    <p style={contactInfoStyle}>Call Us: +91-9823900564</p>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: '10px 0' }}>
                        <li style={{ display: 'inline', marginRight: '10px' }}>
                            <a href="https://www.facebook.com/pushkarrangmanch/">
                                <i className="fab fa-facebook" />
                            </a>
                        </li>
                        <li style={{ display: 'inline' }}>
                            <a href="https://www.instagram.com/pushkarrangmanch/?hl=en">
                                <i className="fab fa-instagram" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div style={{ backgroundColor: '#333', color: '#fff', padding: '10px 0' }}>
                <p style={{ margin: 0 }}>© {new Date().getFullYear()} Puskar Rangmanch. All Rights Reserved.</p>
            </div>
        </footer>
    );
}
